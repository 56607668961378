<template>
  <div class="home">

    <div class="home-banner" v-if="localStorageType == 1">
      <img src="@/assets/banner/m1.png" alt="" />
      <div class="home-banner-content">
<!--        <h1>开源医疗行业应用领航者</h1>-->
      </div>
    </div>

    <div class="home-banner" v-if="localStorageType == 2">
      <img src="@/assets/banner/m2.png" alt="" />
      <div class="home-banner-content">
<!--        <h1>开源企业服务应用领航者</h1>-->
      </div>
    </div>


    <div id="production" class="home-resolve">

  <template v-if="localStorageType == 1">
      <h1 class="home-title">面向丰富业务场景的医疗解决方案</h1>
      <div class="divider">
        <label />
      </div>
      <h2 class="home-subtitle">MEDICAL SOLUTIONS</h2>
  </template>

      <template v-if="localStorageType == 2">
        <h1 class="home-title">面向丰富业务场景的企业应用解决方案</h1>
        <div class="divider">
          <label />
        </div>
        <h2 class="home-subtitle">ENTERPRISE SOLUTIONS</h2>
      </template>


      <div class="home-resolve-banner">
        <img src="@/assets/banner/2.png" alt="" />
      </div>
      <div class="home-resolve-list">
        <div
            v-for="(value, key) in versionCompanyProductMap"
            :key="key"
          class="home-resolve-cell"
          @click="gotoDetail(item.productId)"
        >
          <template v-if="value!=null && value[0]!=null">
          <img :src="value[0].productLogo" alt="" />
          <div>
            <p class="title">{{ value[0].productName }}</p>
            <p class="desc">{{ formatDateTime(value[0].createTime) }}</p>
            <p class="desc">{{ value[0].productIntroduction }}</p>
            <table>
              <tr v-for="item in value" :key="item.id">
                <td class="left-column">{{item.moduleName}}</td>
                <td class="right-column">

                  <div class="op1">

                    <span v-if="item.sourceCodeId!=null" @click="gotoDetail(item.sourceCodeId)">
                源代码
              </span>
                    <span v-if="item.installFileId!=null"  @click="gotoDetail(item.installFileId)">
                安装包
              </span>
                    <span v-if="item.documentId!=null" @click="gotoDetail(item.documentId)">
                技术文档
              </span>
                  </div>

                </td>
              </tr>
            </table>
          </div>
          </template>
        </div>


        <div
            class="home-resolve-cell"
            v-if="whitePaper.productName!=null"
        >
          <img :src="whitePaper.productLogo" alt="" />
          <div>
            <p class="title">{{ whitePaper.productName }}</p>
            <p class="desc">发布日期：{{ formatDateTime(whitePaper.createTime) }}</p>
            <p class="desc">{{ whitePaper.productIntroduction }}</p>
            <table>
              <tr>
                <td class="left-column">白皮书</td>
                <td class="right-column">

                  <div class="op1">
              <span @click="gotoDetail(whitePaper.productId)">
                下载
              </span>
                  </div>

                </td>
              </tr>
            </table>

          </div>
        </div>

      </div>
    </div>


    <div id="news" class="home-news">
      <h1 class="home-title">众多客户的共同选择</h1>
      <div class="divider">
        <label />
      </div>
      <h2 class="home-subtitle">COOPERATIVE PARTNER</h2>
    </div>
    <template v-if="localStorageType==1">
      <img id="case" class="home-select" src="@/assets/banner/3.png" alt="" />
    </template>
    <template v-else-if="localStorageType==2">
      <img id="case" class="home-select" src="@/assets/banner/31.png" alt="" />
    </template>


    <div id="news" class="home-news">
      <h1 class="home-title">行业资讯</h1>
      <div class="divider">
        <label />
      </div>
      <h2 class="home-subtitle">INDUSTRY INFORMATION</h2>
      <div class="home-resolve-list">
        <el-card  class="image-container" v-for="item in newsList" :key="item.id">
          <img @click="goNewDetail(item.linkUrl)" :src="!item.infoPicture ? require('@/assets/banner/4.png') : item.infoPicture " alt="建议大小为180px * 320px的图片" style="height: 180px;width: 320px"/>
          <p  @click="goNewDetail(item.linkUrl)" style="font-size: 14px ;font-weight: bold; color: #0f1010 " class="truncate" v-text="truncatedInfoTitle(item.infoTitle)"></p>
          <span style="font-size: 12px;color: #a1a5a6"> {{item.createTime | formatDateTime}}</span>
        </el-card>
      </div>

    </div>
  </div>
</template>

<script>
import {getCompanyInfo, getCompanyProduct, getInfoNewsList, getWhitePaper} from "@/api/product";
import moment from "moment";

export default {
  filters: {
    formatDateTime(dateTimeString) {
      const dateTime = new Date(dateTimeString);
      return dateTime.toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        // hour: '2-digit',
        // minute: '2-digit',
        // second: '2-digit'
      });
    }
  },
  data() {
    return {
      companyProductList: [],
      versionCompanyProductMap: {},
      whitePaper:{},
      newsList: [],
      productId: 0,
      companyInfoData: {},
      localStorageType: localStorage.getItem("type") || 2,
    };
  },
  methods: {
    // 获取接口数据
    getData() {
      // let id = this.$route.params.id;
      getCompanyProduct({ companyId: 449 }).then((res) => {
        if (res.code === 200) {
          this.versionCompanyProductMap = res.data;
          // if (res.data && res.data[0]) {
          //   this.productId = res.data[0].productId;
          // }
          // res.data.map((item) => {
          //   item.createTime = moment(item.createTime).format("YYYY-MM-DD");
          // });
        }
      });
      getWhitePaper({ companyId: 449 }).then((res) => {
        if (res.code === 200) {
          this.whitePaper = res.data;
        }
      });

      getCompanyInfo({ companyId: 449 }).then((res) => {
        if (res.code === 200) {
          this.companyInfoData = res.data;
          this.companyInfoData.companyLogo = this.companyInfoData.companyLogo
            ? this.companyInfoData.companyLogo
            : "/imgs/product/companyLogo.png";
        }
      });

      getInfoNewsList().then((res) => {
        if (res.code === 200) {
          var newsListDto = res.data;
          this.newsList = newsListDto.infoNewsList;
        }
      });
    },
    // 跳转到指定页面
    gotoDetail(id) {
      this.$router.push({
        name: "detail",
        params: { id },
      });
    },
    formatDateTime(dateTime) {
      if(dateTime){
        return moment(dateTime).format('YYYY-MM-DD HH:mm:ss');
      }
      return "";
    },
    goNewDetail(val){
      const url = val === null ? 'https://crowdsourcing.tntlinking.com' : val;
      window.open(url, '_blank');
    },
    truncatedInfoTitle(title) {
      // 如果标题长度大于 10，则截取前 10 个字符并添加省略号
      if (title.length > 22) {
        return title.substring(0, 20) + '...';
      }
      return title;
    }
  },

  created() {
    this.getData();
  },
};
</script>

<style scoped lang="scss">
.image-container {
  position: relative;
  display: inline-block;
  box-shadow: none;
  border: 0;
  margin: 10px;
}
.home {
  &-banner {
    position: relative;

    img {
      width: 100%;
    }

    &-content {
      position: absolute;
      top: 16px;
      left: 16px;

      h1 {
        font-size: 18px;
        color: #333;
        margin-bottom: 16px;
      }
    }
  }

  &-title {
    font-size: 18px;
    color: #262c33;
    text-align: center;
  }

  &-subtitle {
    font-size: 12px;
    color: #262c33;
    text-align: center;
  }

  .divider {
    text-align: center;

    label {
      display: inline-block;
      width: 240px;
      height: 1px;
      background-color: #0f1010;
    }
  }

  &-resolve {
    &-banner {
      margin: 0 auto;
      width: 100vw;
      position: relative;
      text-align: center;

      img {
        width: 100%;
      }
    }

    &-list {
      margin: 0 auto;
      width: 100vw;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &-cell {
      cursor: pointer;
      display: flex;
      width: calc(100vw - 32px);
      padding: 24px;
      box-shadow: 4px 8px 8px 8px #f5f5f5;
      border-radius: 6px 6px 6px 6px;
      background-color: white;
      margin-top: 12px;

      img {
        width: 64px;
        height: 64px;
        margin-right: 24px;
      }

      p {
        margin-block-start: 0;
        margin-block-end: 0;
      }

      .title {
        font-size: 20px;
        color: #262c33;
        line-height: 26px;
      }

      .desc {
        font-size: 16px;
        color: #262c33;
        line-height: 24px;
        margin-top: 8px;
      }

      .op {
        margin-top: 20px;

        span {
          font-size: 16px;
          color: #748db1;
          cursor: pointer;
        }

        span + span {
          margin-left: 16px;
        }
      }
    }
  }

  &-select {
    width: 100%;
    margin: 20px 0;
  }

  &-news {
    &-content {
      width: calc(100vw - 32px);
      padding: 16px;

      img {
        width: 100%;
      }

      &-cell {
        padding-top: 24px;
        padding-bottom: 40px;
        border-bottom: 1px solid #eee;

        .title {
          font-size: 22px;
          color: #262c33;
          line-height: 35px;
        }

        .desc {
          font-size: 16px;
          color: #a8abad;
          line-height: 24px;
          margin-top: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    &-more {
      display: flex;
      justify-content: center;
      margin-top: 42px;
      margin-bottom: 50px;

      div {
        cursor: pointer;
        padding: 8px 50px;
        color: #333;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #333;
      }
    }
  }
}
</style>
