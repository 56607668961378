import request from "@/util/request";

export const productDetail = data => {
    return request({
        url: "/new_gateway/codepower-rest-api/product/details",
        method: "get",
        params: data
    });
};


// 公司产品
export const getCompanyProduct = data => {
    return request({
        url: "/new_gateway/codepower-rest-api/company/getCompanyProduct",
        method: "get",
        params: data
    });
};

export const getAllCompanyProduct = data => {
    return request({
        url: "/new_gateway/codepower-rest-api/company/getAllCompanyProduct",
        method: "get",
        params: data
    });
};

export const getWhitePaper = data => {
    return request({
        url: "/new_gateway/codepower-rest-api/company/getWhitePaper",
        method: "get",
        params: data
    });
};

// 公司信息
export const getCompanyInfo = data => {
    return request({
        url: "/new_gateway/codepower-rest-api/company/getCompanyInfo",
        method: "get",
        params: data
    });
};

export const getInfoNewsList = data => {
    return request({
        url: "/new_gateway/codepower-rest-api/infoNews/list/1/3",
        method: "get",
        params: data
    });
};

// export const getNewsDetail = (id) => {
//     return request({
//         url: `/new_gateway/codepower-rest-api/infoNews/get/${id}`,
//         method: "get",
//     });
// };

export const getXinzhiUserInfo = (id) => {
    return request({
        url: `/new_gateway/codepower-rest-api/user/xinzhiToken/${id}`,
        method: "get",
    });
};

export const getUserTokenUserInfo = (id) => {
    return request({
        url: `/new_gateway/codepower-rest-api/user/userToken/${id}`,
        method: "get",
    });
};


export const getNewsDetail = (id) => {
    return request({
        url: `/new_gateway/codepower-rest-api/infoNews/get/${id}`,
        method: "get",
    });
};


// 申请签约和试用
export const applyContract = data => {
    return request({
        url: "/new_gateway/codepower-rest-api/apply/contract",
        method: "post",
        data: data
    });
};

//下载当前sku的源码文件
export const downloadSkuFile = data => {
    return request({
        url: "/new_gateway/codepower-rest-api/apply/downloadSkuFile",
        method: "get",
        params: data,
        // responseType: 'arraybuffer'
    });
};

//  获取当前产品的的签约申请状态
export const getApplyContractStatus = data => {
    return request({
        url: "/new_gateway/codepower-rest-api/apply/getApplyContractStatus",
        method: "get",
        params: data
    });
};


// 获取文件大小
export const getFileSize = data => {
    return request({
        url: "/new_gateway/codepower-rest-api/apply/getFileSize",
        method: "get",
        params: data
    });
};
